import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '个人中心',
    component: () => import(/* webpackChunkName: "wx_index" */ '../views/Home.vue')
  },
  {
    path: '/phone',
    name: '绑定手机号',
    component: () => import(/* webpackChunkName: "wx_index" */ '../views/BindPhone.vue')
  },
  {
    path: '/phone_mingyu',
    name: '绑定手机号',
    component: () => import(/* webpackChunkName: "wx_index" */ '../views/BindPhone_Mingyu.vue')
  },
  {
    path: '/userinfo',
    name: '资料修改',
    component: () => import(/* webpackChunkName: "wx_index" */ '../views/UserInfo.vue')
  },
  {
    path: '/chat',
    name: '在线咨询',
    component: () => import(/* webpackChunkName: "wx_index" */ '../views/Chat.vue')
  },
  {
    path: '/revisit',
    name: '复诊问卷',
    component: () => import(/* webpackChunkName: "wx_index" */ '../views/RevisitPage.vue')
  },
  {
    path: '/questionnaire',
    name: '问卷',
    component: () => import(/* webpackChunkName: "wx_index" */ '../views/Questionnaire.vue')
  },
  {
    path: '/consent',
    name: '知情同意',
    component: () => import(/* webpackChunkName: "wx_index" */ '../views/ConsentPage.vue')
  },
  {
    path: '/agreement',
    name: '艾尔兴服务协议',
    component: () => import(/* webpackChunkName: "wx_index" */ '../views/AgreementPage.vue')
  },
  {
    path: '/auth',
    name: '艾尔兴服务协议',
    component: () => import(/* webpackChunkName: "wx_index" */ '../views/AuthPage.vue')
  },
  {
    path: '/record',
    name: '使用情况',
    component: () => import(/* webpackChunkName: "wx_index" */ '../views/UserRecords.vue')
  },
  {
    path: '/session',
    name: '在线咨询',
    component: () => import(/* webpackChunkName: "wx_index" */ '../views/SessionList.vue')
  },
  {
    path: '/visits',
    name: '健康档案',
    component: () => import(/* webpackChunkName: "wx_index" */ '../views/VisitsList.vue')
  },
  {
    path: '/return',
    name: '满意度调查问卷',
    component: () => import(/* webpackChunkName: "wx_index" */ '../views/UserReturnInfo.vue')
  },
  {
    path: '/chart',
    name: '视力曲线',
    component: () => import(/* webpackChunkName: "wx_chart" */ '../views/VisitChart.vue')
  },
  {
    path: '/points',
    name: '我的积分',
    component: () => import(/* webpackChunkName: "wx_chart" */ '../views/PointsRecord.vue')
  },
  {
    path: '/uploadData',
    name: '数据上传',
    component: () => import(/* webpackChunkName: "wx_index" */'../views/UploadData.vue')
  },
  {
    path: '/qrcode',
    name: '售后服务',
    component: () => import(/* webpackChunkName: "wx_index" */'../views/QRCodePage.vue')
  },
  {
    path: '/unsubscribe',
    name: '请先关注',
    component: () => import(/* webpackChunkName: "wx_index" */'../views/Unsubscribe.vue')
  },
  {
    path: '/gift',
    name: '积分兑换',
    component: () => import(/* webpackChunkName: "wx_index" */'../views/PointsPage.vue')
  },
  {
    path: '/doctorlist',
    name: '医生列表',
    component: () => import(/* webpackChunkName: "wx_index" */'../views/DoctorList.vue')
  },
  {
    path: '/clocked/list',
    name: '打卡统计',
    component: () => import(/* webpackChunkName: "clocked" */ '../views/ClockedCalendar.vue')
  },
  {
    path: '/deposit',
    name: '押金支付',
    component: () => import(/* webpackChunkName: "wx_index" */ '../views/DepositPage.vue')
  },
  {
    path: '/payments',
    name: '支付记录',
    component: () => import(/* webpackChunkName: "wx_index" */ '../views/Payments.vue')
  },
  {
    path: '/coupons',
    name: '我的优惠券',
    component: () => import(/* webpackChunkName: "wx_index" */  '../views/Coupons.vue')
  },
  {
    path: '/coupon',
    name: '输入串码',
    component: () => import(/* webpackChunkName: "wx_index" */  '../views/Coupon.vue')
  },
  {
    path: '/pay',
    name: '充值',
    component: () => import(/* webpackChunkName: "wx_index" */  '../views/PayPage.vue')
  },
  {
    path: '/clockin/today',
    name: '每日打卡',
    component: () => import(/* webpackChunkName: "clocked" */ '../views/ClockToday.vue')
  },
  //  销售医生
  {
    path: '/doctor',
    component: () => import(/* webpackChunkName: "doctor" */ '../views/DoctorApp.vue'),
    children: [
      {
        // 当 /user/:id/profile 匹配成功，
        // UserProfile 会被渲染在 User 的 <router-view> 中
        name: '个人中心',
        path: '/doctor',
        component: () => import(/* webpackChunkName: "doctor" */ '../views/DoctorHome.vue')
      },
      {
        path: '/doctor/info',
        name: '资料修改',
        component: () => import(/* webpackChunkName: "doctor" */ '../views/DoctorInfo.vue')
      },
      {
        path: '/doctor/user',
        name: '相关患者',
        component: () => import(/* webpackChunkName: "doctor" */ '../views/UserList.vue')
      },
      {
        path: '/doctor/order',
        name: '患者订单',
        component: () => import(/* webpackChunkName: "doctor" */ '../views/OrderList.vue')
      },
      {
        path: '/doctor/visit',
        name: '完善报告',
        component: () => import(/* webpackChunkName: "doctor" */ '../views/EdivUserVisit.vue')
      }
    ],
    // beforeEnter: (to, from, next) => {
    //   var user = global.defaultStore.state.user
    //   console.log(user)
    //   if (user && user.user && user.user.openid) {
    //     if (user.user.type === "saleman") {
    //       next()
    //     } else {
    //       next('/')
    //     }
    //   } else {
    //     next('/phone')
    //   }
    // }
  },
  //  医院后台医生
  {
    path: '/hospital_doctor',
    component: () => import(/* webpackChunkName: "doctor" */ '../views/HospitalDoctor/HospitalDoctorApp.vue'),
    children: [
      {
        name: '个人中心',
        path: '/hospital_doctor',
        component: () => import(/* webpackChunkName: "doctor" */ '../views/HospitalDoctor/HospitalDoctorList.vue')
      },
      {
        name: '问题列表',
        path: '/hospital_doctor/questions',
        component: () => import(/* webpackChunkName: "doctor" */ '../views/HospitalDoctor/HospitalQuestionList.vue')
      },
      {
        name: '用户资料',
        path: '/hospital_doctor/user/:id',
        component: () => import(/* webpackChunkName: "doctor" */ '../views/HospitalDoctor/VisitsList.vue')
      },
      {
        name: '用户资料',
        path: '/hospital_doctor/user/record/:id',
        component: () => import(/* webpackChunkName: "doctor" */ '../views/HospitalDoctor/UserRecords.vue')
      },
      {
        name: '用户资料',
        path: '/hospital_doctor/user/chart/:id',
        component: () => import(/* webpackChunkName: "doctor" */ '../views/HospitalDoctor/VisitChart.vue')
      },
    ],
  }
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.name) {
    document.title = to.name
  }
  next()
})
export default router
