
import api from '@/utils/api'

const state = {
}

const getters = {
}

const actions = {
  payDeposit ({ commit }, payload) {
    return api('/api/pay/deposit', 'PUT', payload)
  },
  refundByUser ({ commit }, payload) {
    return api('/api/payment/refund', 'PUT', payload)
  },
  getPayments ({ commit }, payload) {
    return api('/api/payments', 'GET', payload)
  },
  payPackage ({ commit }, payload) {
    return api('/api/pay/package', 'PUT', payload)
  },
}
const mutations = {
}

export default {
  state,
  mutations,
  getters,
  actions
}
