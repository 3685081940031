
import api from '@/utils/api'
const state = {
  user: {
  }
}
const getters = {
  getUser (state) {
    return state
  }
}

const actions = {
  setUser (contaxt, user) {
    contaxt.commit('SET_USER', user)
  },
  addLog ({ commit }, payload) {
    return api('/api/log', 'POST', payload)
  },
  addQA ({ commit }, payload) {
    return api('/api/qa', 'POST', payload)
  },
  getUsersByDoctorId ({ commit }, payload) {
    return api('/api/doctor/users', 'GET', payload)
  },
  getUserByDoctorId ({ commit }, payload) {
    return api('/api/doctor/user', 'GET', payload)
  },
  getAgreement ({ commit }, payload) {
    return api('/api/agreement', 'GET', payload)
  },
  signAgreement ({ commit }, payload) {
    return api('/api/agreement', 'POST', payload)
  },
  addDoctorQuestion ({ commit }, payload) {
    return api('/api/doctor/question', 'POST', payload)
  },
  getHospitalQuestions ({ commit }, payload) {
    return api('/api/doctor/questions', 'GET', payload)
  },
  updateVisitInfo ({ commit }, payload) {
    return api('/api/visit/info', 'POST', payload)
  },
  getUsersByOpenId ({ commit }, payload) {
    return api('/api/users', 'GET', payload)
  },
  getUserInfo ({ commit }, payload) {
    return api('/api/user', 'GET', payload)
  },
  updateUserInfo ({ commit }, payload) {
    return api('/api/user', 'PUT', payload)
  },
  getUserPointsRecord ({ commit }, payload) {
    return api('/api/user/points', 'GET', payload)
  },
  updateDoctorInfo ({ commit }, payload) {
    return api('/api/doctor', 'PUT', payload)
  },
  getCustomers ({ commit }, payload) {
    return api('/api/customers', 'GET', payload)
  },
  getDoctors ({ commit }, payload) {
    return api('/api/doctors', 'GET', payload)
  },
  sendSMS ({ commit }, payload) {
    return api('/api/sms/code', 'POST', payload)
  },
  sendMingyuSMS ({ commit }, payload) {
    return api('/api/sms/code/mingyu', 'POST', payload)
  },
  bindMobile ({ commit }, payload) {
    return api('/api/mobile', 'POST', payload)
  },
  uploadImage ({ commit }, payload) {
    return api('/api/image', 'POST', payload)
  },
  returnBooking ({ commit }, payload) {
    return api('/api/booking/return', 'POST', payload)
  },
  uploadImageByClockin ({ commit }, payload) {
    return api('/api/clockin', 'POST', payload)
  },
  getClockinRecords ({ commit }, payload) {
    return api('/api/clockin', 'GET', payload)
  },
  getUser ({ commit }, payload) {
    return api('/api/admin/user', 'GET', payload)
  },
  getHospitalsName ({ commit }, payload) {
    return api('/api/hospitals/name', 'GET', payload)
  },
  getHospitalDoctors ({ commit }, payload) {
    return api('/api/hospital/doctor', 'GET', payload)
  }
}

const mutations = {
  SET_USER (state, { user }) {
    state.user = user
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
