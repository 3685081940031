import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../plugins/axios'
window.wx = require('weixin-js-sdk');
import StarRating from 'vue-star-rating'
import { Button, Switch, Cell, Field, DatetimePicker, Picker, Tabbar, TabItem, Popup, Navbar, TabContainer, TabContainerItem } from 'mint-ui'
Vue.component('star-rating', StarRating);
Vue.component(Switch.name, Switch)
Vue.component(Button.name, Button)
Vue.component(Cell.name, Cell)
Vue.component(Field.name, Field)
Vue.component(DatetimePicker.name, DatetimePicker)
Vue.component(Picker.name, Picker)
Vue.component(Popup.name, Popup)
Vue.component(Navbar.name, Navbar)
Vue.component(TabContainer.name, TabContainer)
Vue.component(TabContainerItem.name, TabContainerItem)

Vue.component(Tabbar.name, Tabbar)
Vue.component(TabItem.name, TabItem)
// import vConsole from 'vconsole'
// var vc = new vConsole();
Vue.config.productionTip = false
window.defaultStore = store
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')