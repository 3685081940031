
import api from '@/utils/api'

const state = {
}

const getters = {
}

const actions = {
  getPackages ({ commit }, payload) {
    return api('/api/packages', 'GET', payload)
  },
}

const mutations = {
}

export default {
  state,
  mutations,
  getters,
  actions
}
