<template>
  <div id="app">
    <router-view v-if="!loaded" />
    <div v-if="!isWX">
      <div style="padding: 30vh 0;">
        <h1 style="margin-top: 0">网站即将上线</h1>
        <h1>敬请期待</h1>
      </div>
      <div>
        <h2>广州明煜视觉科技有限公司</h2>
        <h2><a style="color: #2c3e50" href="http://www.beian.miit.gov.cn/">粤ICP备20008362号-1</a></h2>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import { Toast, Indicator } from 'mint-ui'

import process from 'process'
import axios from 'axios'
const config = window.config
export default {
  name: 'App',
  computed: mapState([
    'user'
  ]),
  data() {
    return {
      loaded: true,
      isWX: true
    }
  },
  beforeMount: function() {
    // dev
    // if (process.env.NODE_ENV !== 'production') {
    //   this.getUserByToken()
    // }
    this.isWX = this.isWeiXin()
    if (this.isWX) {
      this.initWx()
      Indicator.open({
        text: '加载中...',
        spinnerType: 'fading-circle'
      });
    } else {
      document.title = '明煜视觉'
      window.location.href = 'https://my-eye.com.cn/web'
    }

  },
  methods: {
    getUser: function () {
      // this.getUserByWX()
      const token = localStorage.getItem('token')
      const fromPlatform = localStorage.getItem('fromPlatform')
      if (token && fromPlatform === config.fromPlatform) {
        this.getUserByToken()
      } else {
        this.getUserByWX()
      }
    },
    closeLoad: function () {
      this.loaded = false
      Indicator.close()
    },
    getUserByWX: function (){
      const code = this.getQueryVariable('code')
      let action = config.fromPlatform === 'mingyu' ? 'signinmingyu' : 'signin'
      console.log(action)
      this.$store.httpDispatch({})(action, {
        code
      }).then((res) => {
        this.closeLoad()
        const { code, data } = res.data
        if (code === 0) {
          if (!data.user.subscribed) {
            this.$router.push('/unsubscribe')
            return
          }
          if (data.user.type === 'hospital_doctor' && location.hash.indexOf('hospital_doctor') === -1) {
            this.$router.replace('/hospital_doctor')
          }
          if (!data.user.mobile && data.user.type === 'normal') {
            if (config.fromPlatform === 'mingyu') {
              this.$router.replace('/phone_mingyu')
            } else {
              this.$router.replace('/phone')
            }
          }
          if (data.user.type === 'saleman') {
            this.$router.replace('/doctor')
          }
          data.tokenExpiredAt = new Date(data.tokenExpiredAt)
          localStorage.setItem('token', data.token)
          localStorage.setItem('fromPlatform', config.fromPlatform)
          localStorage.setItem('tokenExpiredAt', data.tokenExpiredAt.getTime())
          this.$store.commit('ACCOUNT_RESTORE', {
            tokenExpiredAt: data.tokenExpiredAt,
            token: data.token
          })
          this.$store.dispatch('setUser', { user: data.user })
        } else {
          return Toast({
            message: data.msg,
            duration: 3000
          })
        }
      }).catch((err) => {
        this.closeLoad()
        console.log(err)
      })

    },
    getUserByToken: function () {
      const token = localStorage.getItem('token')
      this.$store.httpDispatch({})('getUserInfo').then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          this.closeLoad()
          if (!data.user.subscribed) {
            this.$router.push('/unsubscribe')
            return
          }
          if (!data.user.mobile && data.user.type === 'normal') {
            if (config.fromPlatform === 'mingyu') {
              this.$router.replace('/phone_mingyu')
            } else {
              this.$router.replace('/phone')
            }
          }
          if (data.user.type === 'saleman') {
            this.$router.replace('/doctor')
          }
          if (data.user.type === 'hospital_doctor' && location.hash.indexOf('hospital_doctor') === -1) {
            this.$router.replace('/hospital_doctor')
          }
          this.$store.dispatch('setUser', { user: data.user })
        } else {
          localStorage.clear('token')
          localStorage.clear('tokenExpiredAt')
          localStorage.clear('fromPlatform')
          this.getUser()
        }
      }).catch((err) => {
        localStorage.clear('token')
        localStorage.clear('fromPlatform')
        localStorage.clear('tokenExpiredAt')
        console.log(err)
        this.getUser()
      })
    },
    getQueryVariable: function (variable) {
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
          var pair = vars[i].split("=");
          if(pair[0] == variable){return pair[1];}
       }
       return(false);
    },
    isWeiXin () {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger' || ua.match(/_SQ_/i) == '_sq_') {
        return true;
      } else {
        return false;
      }
    },
    initWx () {
      let path = '/api/signature'
      if (config.fromPlatform === 'mingyu') {
        path = '/api/signature/mingyu'
      }
      const that = this
      axios.get(path, {
        params: {
          url: location.href
        }
      })
        .then((res) => {
          const { code, data } = res. data
          if (code === 0) {
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: config.appId, // 必填，公众号的唯一标识
              timestamp: data.timestamp, // 必填，生成签名的时间戳
              nonceStr: data.nonceStr, // 必填，生成签名的随机串
              signature: data.signature,// 必填，签名
              jsApiList: ['chooseImage', 'uploadImage'] // 必填，需要使用的JS接口列表
            });
          }
        })
      wx.ready(function(){
        console.log('wx ready')
        that.getUser()
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      });
      wx.error(function(res){
        console.log(res)
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      })
    }
  }
}
</script>
<style lang="less">
body {
  margin: 0 0;
  padding: 0 0
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #D0D0D0;
  min-height: 100vh;
  input,button,select,textarea{outline:none}
  select {
    -webkit-appearance: none;
  }
}
#nav {
  padding: 30px;
  position: fixed;
  top: 0;
  width: 100vw;
  background: #fff;
  box-sizing: border-box;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
#__vconsole .vc-toolbar, #__vconsole .vc-switch {
  bottom: 200px!important;
}
</style>
