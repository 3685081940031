import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import api from '../../plugins/api'

import user from './modules/user'
import account from './modules/account'
import chat from './modules/chat'
import info from './modules/info'
import wxpay from './modules/wxpay'
import visit from './modules/visit'
import coupon from './modules/coupon'
import plan from './modules/plan'
Vue.use(Vuex)
Vue.use(api(Vuex))

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    wxpay,
    account,
    chat,
    info,
    plan,
    coupon,
    visit
  },
  plugins: [createLogger({})]
})
