import _ from 'lodash'
import api from '@/utils/api'
var token = localStorage.getItem('token') || ''
var tokenExpiredAt = new Date(parseInt(localStorage.getItem('tokenExpiredAt') || ''))
if (tokenExpiredAt <= new Date() || tokenExpiredAt.toString() === 'Invalid Date') {
  token = ''
  tokenExpiredAt = new Date()
}

export default {
  state: {
    token,
    tokenExpiredAt
  },

  mutations: {
    ACCOUNT_RESTORE (state, account) {
      _.assign(state, _.pick(account, ['token', 'tokenExpiredAt']))
    }
  },
  actions: {
    signin ({ commit }, payload) {
      return api('/api/access_token', 'GET', payload)
    },
    getAuthToken ({ commit }, payload) {
      return api('/api/access_token/wxpay', 'GET', payload)
    },
    signinmingyu ({ commit }, payload) {
      return api('/api/access_token/mingyu', 'GET', payload)
    },
    signinByToken ({ commit }) {
      return api('/api/signin/token', 'POST')
    },
    resetPassword ({ commit }, payload) {
      return api('/api/resetpassword', 'POST', payload)
    },
    getSetting ({ commit }, payload) {
      return api('/api/setting', 'GET', payload)
    }
  },
  getters: {
    account (state) {
      return {
        token: state.token,
        tokenExpiredAt: state.tokenExpiredAt
      }
    }
  }
}
