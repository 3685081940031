
import api from '@/utils/api'

const state = {
}

const getters = {
}

const actions = {
  getVisits ({ commit }, payload) {
    return api('/api/visits', 'GET', payload)
  },
  getVisit ({ commit }, payload) {
    return api('/api/admin/visit', 'GET', payload)
  },
  updateVisit ({ commit }, payload) {
    return api('/api/admin/visit', 'POST', payload)
  }
}

const mutations = {
}

export default {
  state,
  mutations,
  getters,
  actions
}
