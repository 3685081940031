
import api from '@/utils/api'

const state = {
  logs: {}
}

const getters = {
}

const actions = {
  getMessage ({ commit }, payload) {
    return api('/api/message', 'GET', payload)
  },
  getSessionsByDoctor ({ commit }, payload) {
    return api('/api/sessions/doctor', 'GET', payload)
  },
  getSessionsByCustomer({ commit }, payload) {
    return api('/api/sessions/customer', 'GET', payload)
  },
  getSessionById ({ commit }, payload) {
    return api('/api/session', 'GET', payload)
  },
  putSessionCustomer ({ commit }, payload) {
    return api('/api/session/customer', 'PUT', payload)
  },
  putSessionDoctor ({ commit }, payload) {
    return api('/api/session/doctor', 'PUT', payload)
  },
  closeSession ({ commit }, payload) {
    return api('/api/session', 'PUT', payload)
  },
  getSessionByFrom ({ commit }, payload) {
    return api('/api/session/from', 'GET', payload)
  },
  sendMessage ({ commit }, payload) {
    return api('/api/message', 'POST', payload)
  },
  updateOnline ({ commit }, payload) {
    return api('/api/online', 'POST', payload)
  }
}

const mutations = {
}

export default {
  state,
  mutations,
  getters,
  actions
}
