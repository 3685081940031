
import api from '@/utils/api'

const state = {
}

const getters = {
}

const actions = {
  updateUserBackInfo ({ commit }, payload) {
    return api('/api/info', 'PUT', payload)
  },
  getInfo ({ commit }, payload) {
    return api('/api/info', 'GET', payload)
  }
}

const mutations = {
}

export default {
  state,
  mutations,
  getters,
  actions
}
